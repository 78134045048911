@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  /* Light mode */
  --light-mode-primary-color: #edf2f8;
  --light-mode-secondary-color: #4f8ecb;
  --light-mode-black-color: #030303;
  --light-mode-lightGray-color: #e4e4e4;
  --light-mode-gray-color: #6b7688;
  --light-mode-brown-color: #46364a;
  --light-mode-white-color: #ffffff;
  --light-mode-nabvar-bg: #ffffff40;
  --light-mode-nabvar-border: rgba(255, 255, 255, 0.18);

  /* Dark mode */
  --dark-mode-primary-color: #292d33; /* 181818 */
  --dark-mode-secondary-color: #4f8ecb;
  --dark-mode-black-color: #edf2f8;
  --dark-mode-lightGray-color: #24272b;
  --dark-mode-gray-color: #e4e4e4;
  --dark-mode-brown-color: #46364a;
  --dark-mode-white-color: #181818;
  --dark-mode-nabvar-bg: #00000020;
  --dark-mode-nabvar-border: rgba(0, 0, 0, 0.18);

  /* Default to light mode */
  --primary-color: var(--light-mode-primary-color);
  --secondary-color: var(--light-mode-secondary-color);
  --black-color: var(--light-mode-black-color);
  --lightGray-color: var(--light-mode-lightGray-color);
  --gray-color: var(--light-mode-gray-color);
  --brown-color: var(--light-mode-brown-color);
  --white-color: var(--light-mode-white-color);
  --nabvar-bg: var(--light-mode-nabvar-bg);
  --nabvar-border: var(--light-mode-nabvar-border);
}

[data-theme="dark"] {
  --primary-color: var(--dark-mode-primary-color);
  --secondary-color: var(--dark-mode-secondary-color);
  --black-color: var(--dark-mode-black-color);
  --lightGray-color: var(--dark-mode-lightGray-color);
  --gray-color: var(--dark-mode-gray-color);
  --brown-color: var(--dark-mode-brown-color);
  --white-color: var(--dark-mode-white-color);
  --nabvar-bg: var(--dark-mode-nabvar-bg);
  --nabvar-border: var(--dark-mode-nabvar-border);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
